@font-face {
  font-family: "Inter";
  src: local("InterThin"), url('../assets/font/Inter-Thin.ttf') format("truetype");
  font-weight: 100; }

@font-face {
  font-family: 'Inter';
  src: local('InterExtraLight'), url('../assets/font/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200; }

@font-face {
  font-family: 'Inter';
  src: local('InterLight'), url('../assets/font/Inter-Light.ttf') format('truetype');
  font-weight: 300; }

@font-face {
  font-family: 'Inter';
  src: local('InterRegular'), url('../assets/font/Inter-Regular.ttf') format('truetype');
  font-weight: 400; }

@font-face {
  font-family: 'Inter';
  src: local('InterMedium'), url('../assets/font/Inter-Medium.ttf') format('truetype');
  font-weight: 500; }

@font-face {
  font-family: 'Inter';
  src: local('InterSemiBold'), url('../assets/font/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600; }

@font-face {
  font-family: 'Inter';
  src: local('InterBold'), url('../assets/font/Inter-Bold.ttf') format('truetype');
  font-weight: 700; }

@font-face {
  font-family: 'Inter';
  src: local('InterExtraBold'), url('../assets/font/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800; }

@font-face {
  font-family: 'Inter';
  src: local('InterBlack'), url('../assets/font/Inter-Black.ttf') format('truetype');
  font-weight: 900; }






